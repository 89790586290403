import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToWords } from "to-words";
import moment from "moment";
import UnitechHeaderNew from "../image/certificate_header_nonnabl.png";
import UnitechHeader from "../image/certificate_header_old.png";
import UnitechFooter from "../image/footer.png";
import Stamp from "../image/stamp.png";
import Sign from "../image/tanji.png";
import { BANK_DETAILS, GST_NUMBER } from "../../global";

let config = {
  margin: 20,
  borderMagin: 13,
  headerHeight: 90,
  footerHeight: 0,
  fontSize: 8,
  cusotmerHeight: 50,
  bordergap:6,
  currentY:0,
};

function generateHeaderSection(
  doc,
  quotationAllData,
  newAddressArray,
  contact
) {
  doc.setFont("helvetica", "bold"); // Set font to bold
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 102);
  doc.text(
    "QUOTATION FOR CALIBRATION & TESTING SERVICES",
    doc.internal.pageSize.getWidth() / 2,
    config?.headerHeight + config?.margin + 15,
    { align: "center" }
  );

  const topTableBody = [
    [
      {
        content: "Customer",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      { content: quotationAllData[0]?.companyName || "" },
      {
        content: "Enquiry Date",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      {
        content: `${
          quotationAllData[0]?.enquiryDate
            ? moment(quotationAllData[0].enquiryDate).format("DD/MM/YYYY")
            : ""
        }`,
      },
    ],
    [
      {
        content: "Billing Address",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      { content: newAddressArray?.[0]?.address || "" },
      {
        content: "Quotation Number | Date",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      {
        content: `${quotationAllData[0]?.quotationNumber || ""}${
          quotationAllData[0]?.revisionNumber > 0
            ? `-Rev-${quotationAllData[0]?.revisionNumber}`
            : ""
        } | ${
          quotationAllData[0]?.date
            ? moment(quotationAllData[0]?.date).format("DD/MM/YYYY")
            : ""
        }`,
      },
    ],
    [
      {
        content: "Contact Person",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      { content: contact?.contname || "" },
      {
        content: "Contact Number",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      { content: contact?.contactNumber || "" },
    ],
    [
      {
        content: "E-mail",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      { content: contact?.email || "" },
      {
        content: "Reference Number",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      { content: quotationAllData[0]?.referenceNumber || "" },
    ],
    [
      {
        content: "Quotation Validity",
        styles: {
          // fillColor: [217, 217, 217],
          halign: "left",
          fontStyle: "bold",
        },
      },
      {
        content: `${quotationAllData[0]?.quotationValidity || ""}`,
      },
      // {
      //   content: "Equipment Delivery",
      //   styles: {
      //     // fillColor: [217, 217, 217],
      //     halign: "left",
      //     fontStyle: "bold",
      //   },
      // },
      // { content: quotationAllData[0]?.equipmentDelivery || "" },
    ],
    // [
    //   {
    //     content: newAddressArray?.[0]?.shipping?.address
    //       ? "Shipping Address"
    //       : "",
    //     styles: {
    //       // fillColor: [217, 217, 217],
    //       halign: "left",
    //       fontStyle: "bold",
    //     },
    //   },
    //   { content: newAddressArray?.[0]?.shipping?.address || "" },
    //   {
    //     content: "Equipment Collection",
    //     styles: {
    //       // fillColor: [217, 217, 217],
    //       halign: "left",
    //       fontStyle: "bold",
    //     },
    //   },
    //   { content: quotationAllData[0]?.equipmentCollection || "" },
    // ],
  ];
  const totalPageWidth = doc.internal.pageSize.getWidth() - config.margin * 2;
  const columnPercentages = [20, 30, 25, 25];
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  const startYPos = config?.headerHeight + 17 + config.bordergap + config?.margin;
  doc.autoTable({
    startY: startYPos,
    theme: "grid",
    body: topTableBody,
    styles: {
      fontSize: config.fontSize,
      cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
      textColor: 0,
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
    headStyles: { fillColor: [217, 217, 217] },
    bodyStyles: { valign: "middle" },
    margin: { left: config.margin, right: config.margin },
  });

  const finalY = doc.lastAutoTable.finalY + 1;
  const pageWidth = doc.internal.pageSize.getWidth();
  const marginLeft = config.margin;
  const marginRight = config.margin;
  const boxWidth = pageWidth - marginLeft - marginRight;
  const boxHeight = config.cusotmerHeight;

  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.1);
  doc.setFillColor(255, 255, 255);
  doc.rect(marginLeft, finalY + 1 , boxWidth, boxHeight + config.bordergap, "FD");

  const lines = [
    "Dear Customer:\n",
    "Thank you for the enquiry. Please contact our Business Associate for any further assistance on Calibration | Testing Services at:\n",
    "Quotation: +91 85519 35533 | Operations: +91 9325264428",
  ];

  doc.setFontSize(config.fontSize);
  doc.setTextColor(0);
  let textX = marginLeft + 3;
  let textY = finalY + 15;
  lines.forEach((line) => {
    doc.text(line, textX, textY);
    textY += 15;
  });
}

function calculateAmounts(productDetails, productList, quotationAllData) {
  const amounts = {
    totalAmountBeforeTax: 0,
    totalAmountBeforeDiscount: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: parseFloat(quotationAllData[0]?.conveyanceCharges) || 0,
    courierCharges: parseFloat(quotationAllData[0]?.courierCharges) || 0,
    discount: parseFloat(quotationAllData[0]?.discount) || 0,
    grandTotal: 0,
  };

  amounts.totalAmountBeforeTax = productDetails.reduce((sum, product) => {
    const foundProduct = productList.find(
      ({ id }) => id === product.equipmentId
    );
    product.name = foundProduct?.instrumentName || "";
    const totalAmount = product.rate * product.quantity;
    const discountAmount =
      (totalAmount * (parseFloat(product.discount) || 0)) / 100;
    product.amount = totalAmount - discountAmount;
    return sum + product.amount;
  }, 0);

  amounts.totalAmountBeforeTax +=
    amounts.conveyanceCharges + amounts.courierCharges;
  amounts.totalAmountBeforeDiscount = amounts.totalAmountBeforeTax;
  const discountAmount =
    (amounts.totalAmountBeforeTax * amounts.discount) / 100;
  amounts.totalAmountBeforeTax -= discountAmount;
  const gstPercentage = parseFloat(quotationAllData[0]?.gstpercentages) || 18;
  if (quotationAllData[0]?.gstType === 1) {
    amounts.sgst = amounts.cgst =
      (amounts.totalAmountBeforeTax * gstPercentage) / 200;
    amounts.gst = amounts.sgst + amounts.cgst;
  } else if (quotationAllData[0]?.gstType === 2) {
    amounts.gst = (amounts.totalAmountBeforeTax * gstPercentage) / 100;
  }
  amounts.grandTotal = amounts.totalAmountBeforeTax + amounts.gst;
  return amounts;
}

function generateTableWithSummary(
  doc,
  productDetails,
  productList,
  quotationAllData,
  margin,
  drawMarginRect,
  letterHead
) {
  const toWords = new ToWords();
  const hasDiscount = productDetails.some(
    (product) => product?.discount != null
  );
  const amounts = calculateAmounts(
    productDetails,
    productList,
    quotationAllData
  );
  const columns = [
    "Sr.No.",
    "Description of Item | Equipment - Range",
    "Description",
    "SAC | HSN Code Job Type",
    "Type of Service",
    "Rate",
    "Quantity (No)",
    ...(hasDiscount ? ["Discount (%)"] : []),
    "Total Price (INR)",
  ];
  const rows = productDetails.map((product, i) => [
    i + 1,
    product.requestedName || product.name,
    (product.rangeValue?.replace(/#/g, " ").replace(/\|/g, " to ")?.trim() ||
      "") +
      " " +
      (product.description || ""),
    product.hsnCode || "",
    product.service || "",
    (product.rate || 0).toFixed(2),
    product.quantity || 1,
    ...(hasDiscount ? [`${product.discount || 0} %`] : []),
    (product.amount || 0).toFixed(2),
  ]);
  const footRows = [];
  if (amounts.conveyanceCharges > 0) {
    footRows.push([
      {
        content: "Onsite Charges",
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: amounts.conveyanceCharges.toFixed(2),
        styles: { halign: "right" },
      },
    ]);
  }
  if (amounts.courierCharges > 0) {
    footRows.push([
      {
        content: "Courier Charges",
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: amounts.courierCharges.toFixed(2),
        styles: { halign: "center" },
      },
    ]);
  }
  footRows.push([
    {
      content: "Total Amount",
      colSpan: columns.length - 1,
      styles: { halign: "right", fontStyle: "bold" },
    },
    {
      content: amounts.totalAmountBeforeDiscount.toFixed(2),
      styles: { halign: "center" },
    },
  ]);
  if (amounts.discount) {
    footRows.push([
      {
        content: `Discount`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: `${amounts.discount} %`,
        styles: { halign: "center" },
      },
    ]);
    footRows.push([
      {
        content: "Total Amount After Discount",
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: amounts.totalAmountBeforeTax.toFixed(2),
        styles: { halign: "center" },
      },
    ]);
  }
  if (quotationAllData[0]?.gstType === 1) {
    footRows.push([
      {
        content: `CGST (${(quotationAllData[0]?.gstpercentages || 18) / 2}%)`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      { content: (amounts.gst / 2).toFixed(2), styles: { halign: "center" } },
    ]);
    footRows.push([
      {
        content: `SGST (${(quotationAllData[0]?.gstpercentages || 18) / 2}%)`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      { content: (amounts.gst / 2).toFixed(2), styles: { halign: "center" } },
    ]);
  } else if (quotationAllData[0]?.gstType === 2) {
    footRows.push([
      {
        content: `IGST (${quotationAllData[0]?.gstpercentages || 18}%)`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      { content: amounts.gst.toFixed(2), styles: { halign: "center" } },
    ]);
  }
  footRows.push([
    {
      content: "Grand Total Amount",
      colSpan: columns.length - 1,
      styles: { halign: "right", fontStyle: "bold" },
    },
    { content: amounts.grandTotal.toFixed(2), styles: { halign: "center" } },
  ]);
  footRows.push([
    {
      content: `Amount in Words: : ${toWords.convert(
        amounts.grandTotal ? amounts.grandTotal.toFixed(2) : 0,
        { currency: true }
      )}`,
      colSpan: columns.length,
      styles: { halign: "left", fontStyle: "bold" },
    },
  ]);
  const allRow = [...rows, ...footRows];
  const startY = doc.lastAutoTable
    ? doc.lastAutoTable.finalY + config.cusotmerHeight + 4
    : 60;
  doc.autoTable({
    margin: {
      top: margin + config?.headerHeight + 2,
      right: margin,
      bottom: margin + config?.footerHeight,
      left: margin,
    },
    head: [columns],
    body: allRow,
    // foot: footRows,
    startY: startY,
    theme: "grid",
    styles: {
      fontSize: config.fontSize,
      cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
      textColor: 0,
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    headStyles: {
      fillColor: [156,194,230],
      textColor: [0, 0, 0],
      halign: "center",
      valign: "middle",
    },
    bodyStyles: { halign: "center", valign: "middle" },
    footStyles: { fillColor: [255, 255, 255], textColor: 0, lineWidth: 0.1 },
    didDrawPage: function () {
      drawMarginRect(doc);
      // Add header and footer images on each page if letterHead is true
      if (letterHead) {
        addHeaderFooter(doc);
      }
    },
  });
  const newY = doc.lastAutoTable.finalY;
  config.currentY = newY; 
}

function generateTermsOfService(
  doc,
  quotationAllData,
  settings,
  startY,
  letterHead
) {
  const termsText =
    quotationAllData[0]?.termsConditions || settings["quotation terms"] || "";
  doc.autoTable({
    startY,
    theme: "grid",
    head: [[{ content: "Terms of Service" }]],
    body: [[{ content: termsText }]],
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
    },
    bodyStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      halign: "left",
    },
    margin: { left: config.margin, right: config.margin },
    styles: {
      fontSize: config.fontSize,
      cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    didDrawPage: function () {
      if (letterHead) {
        addHeaderFooter(doc);
      }
    },
  });
  const newY = doc.lastAutoTable.finalY;
  config.currentY = newY; 
}

// Helper function to draw the header and footer images
function addHeaderFooter(doc,letterHeadCompnayType) {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let letterHead = letterHeadCompnayType ? UnitechHeader : UnitechHeaderNew

  // Draw header image at the top (x:0, y:0)
  doc.addImage(letterHead, "PNG", 0, 0, pageWidth, config?.headerHeight);
  doc.addImage(
    UnitechFooter,
    "PNG",
    0,
    pageHeight - config?.footerHeight,
    pageWidth,
    config?.footerHeight
  );
}

export function generatePDF1({
  letterHeadCompnayType,
  quotationProductDetails,
  productList,
  quotationAllData,
  newAddressArray,
  contact,
  settings,
  letterHead,
  electronicSign
}) {
  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    compress: true,
  });
  const totalWidth = doc.internal.pageSize.getWidth();
  const totalHeight = doc.internal.pageSize.getHeight();

  const drawMarginRect = (doc) => {
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.rect(
      config?.borderMagin,
      config?.borderMagin + config?.headerHeight + config.bordergap,
      totalWidth - config?.borderMagin * 2,
      totalHeight -
        config?.borderMagin * 2 -
        (config?.headerHeight + config?.footerHeight) - config.bordergap
    );
  };

  // Generate the content
  generateHeaderSection(doc, quotationAllData, newAddressArray, contact);
  generateTableWithSummary(
    doc,
    quotationProductDetails,
    productList,
    quotationAllData,
    config?.margin,
    drawMarginRect,
    letterHead
  );

  
  const finalY = doc.lastAutoTable ? doc.lastAutoTable.finalY : 60;
  // Calculate remaining height

  const remainingHeight =
  totalHeight - config.currentY - config?.margin - config?.footerHeight;

      const termsHeight = 90;
      if (remainingHeight >= termsHeight) {
        // If enough space is available on the same page
        generateBankDetailsTable(
          doc,
          GST_NUMBER,
          BANK_DETAILS,
          config.currentY + 1,
          config?.margin,
            totalWidth,
            electronicSign,
            letterHeadCompnayType
        );
      } else {
        // If not enough space, create a new page
        doc.addPage();
        generateBankDetailsTable(
          doc,
          GST_NUMBER,
          BANK_DETAILS,
          config?.borderMagin + config?.headerHeight + 5 + config.bordergap,
          config?.margin,
            totalWidth,
            electronicSign,
            letterHeadCompnayType
        );
      }

  const remainingHeight1 =
    totalHeight - config.currentY - config?.margin - config?.footerHeight;

  const termsHeight1 = 70;

   if (remainingHeight1 >= termsHeight1) {
    // If enough space is available on the same page
    generateTermsOfService(
      doc,
      quotationAllData,
      settings,
      config.currentY + 1,
      letterHead
    );
  } else {
    // If not enough space, create a new page
    doc.addPage();
    generateTermsOfService(
      doc,
      quotationAllData,
      settings,
      config?.borderMagin + config?.headerHeight + 5 + config.bordergap,
      letterHead
    );
  }

  

  const totalPages = doc.getNumberOfPages();
    for (let page = 1; page <= totalPages; page++) {
      doc.setPage(page);
      let content = `Page No : ${String(page).padStart(2, "0")} of ${String(
        totalPages
      ).padStart(2, "0")}`;
      doc.text(
        content,
        doc.internal.pageSize.getWidth() - config.margin  - 70,
        config?.headerHeight + config?.margin - 7,
        { align: "left" }
      );
    }

  // In case there are pages that didn't trigger didDrawPage, loop through all pages to add header/footer
  if (letterHead) {
    const pageCount = doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addHeaderFooter(doc,letterHeadCompnayType);
      drawMarginRect(doc);
    }
  }

  doc.save(`${quotationAllData[0]?.companyName}_${quotationAllData[0].quotationNumber}.pdf`);
}


const generateBankDetailsTable = (
  doc,
  GST_NUMBER,
  BANK_DETAILS,
  finalY,
  margin,
  totalWidth,
  electronicSign,
  letterHeadCompnayType
) => {
  const tableData = [
    [
      {
        content:
          "\nBank Details For Payment -\n" +
          `${BANK_DETAILS["Bank Name"]}\n` +
          `Bank A/c. No.: ${letterHeadCompnayType ? BANK_DETAILS["Bank A/c. No."] : "033805011021"}\n` +
          `IFSC Code: ${BANK_DETAILS["IFSC Code"]}\n` +
          `GST NO: ${letterHeadCompnayType ? GST_NUMBER : "27ABYFM9941R1ZR"}`,
        styles: {
          fontStyle: "normal",
          halign: "left",
          cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
          lineHeight: 1.5,
        },
      },
      {
        content: `${letterHeadCompnayType ? "For M V Consultants" : "For M V Consultants LLP"}\n\n\n\n\n\n\nAuthorized Signatory`,
        styles: {
          fontStyle: "bold",
          halign: "center",
          cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
        },
      },
    ],
  ];

  doc.autoTable({
    margin: { top: margin, right: margin, bottom: margin, left: margin },
    startY: finalY + 2,
    body: tableData,
    theme: "plain",
    styles: {
      fontSize: config.fontSize,
      cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
      textColor: 0,
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    columnStyles: {
      0: {
        cellWidth: (totalWidth - margin * 2) * 0.6,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      1: {
        cellWidth: (totalWidth - margin * 2) * 0.4,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
    },
    didDrawCell: (data) => {
      if (data.column.index === 1 && electronicSign) {
        const { x, y, height, width } = data.cell;
        const imageWidth = 50; // Adjust the image width
        const imageHeight = 50; // Adjust the image height
        const centerX = x + (width - imageWidth) / 2;
        // Add the image at the correct position
        doc.addImage(
          Sign,
          "PNG",
          x+45,
          y + (height - imageHeight) / 2 + 10,
          imageWidth,
          imageHeight - 10
        );

        doc.addImage(
          Stamp,
          "PNG",
          centerX + 20,
          y + (height - imageHeight) / 2,
          imageWidth,
          imageHeight
        );
      }
    },
  });
  const newY = doc.lastAutoTable.finalY;
  config.currentY = newY + 2; 
};