import {
  customFontFamily,
  environmentTableConfig,
  footerConfig,
  MasterEQPDetails,
  tableConfiguration,
  totalHeaderHeightWithCalibrationLableorNot,
  UUCDetails,
} from "./datasheetConfig";
import { addNewPage } from "./printDatasheet";
import {
  layoutConfig,
  parseValue,
  printdatasheetConfigData,
  printDatasheetConfiguration,
} from "./datasheetConstant";
import autoTable from "jspdf-autotable";

export const drawfirst2ColumnRowspan = async (panels) => {
  let tableRows = [];
  const rowSpan = panels?.rightPanel?.keys?.length;
  let row = [
    {
      content: panels?.leftPanel.keys[0],
      rowSpan: rowSpan,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.leftPanel.values[0],
      rowSpan: rowSpan,
      styles: { valign: "middle", halign: "left" },
    },
    {
      content: panels?.rightPanel.keys[0],
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.rightPanel.values[0],
      styles: { valign: "middle", halign: "left" },
    },
  ];
  tableRows.push(row);

  for (let i = 1; i < panels?.rightPanel.keys.length; i++) {
    let row = [
      {
        content: panels?.rightPanel.keys[i],
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
          halign: "left",
        },
      },
      {
        content: panels?.rightPanel.values[i],
        styles: { valign: "middle", halign: "left" },
      },
    ];
    tableRows.push(row);
  }

  // Calculate the total available width of the table
  const totalPageWidth =
    printDatasheetConfiguration.pageWidth -
    tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 35, 20, 25];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize:
        panels.tableTextfontSize || printDatasheetConfiguration.fontSizes.h2,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printDatasheetConfiguration.cellPaddingTable,
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printDatasheetConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const firstcolumnHeadersecondValue = async (panels) => {
  const { firstRow, secondRow, column } = panels;
  const tableData = [];
  const combinedRows = [...firstRow, ...secondRow];

  let rowData = [];
  combinedRows.forEach((item, index) => {
    rowData.push(
      {
        content: item.key,
        styles: { halign: "left", fontStyle: "bold", valign: "middle" },
      },
      { content: item.value, styles: { halign: "left", valign: "middle" } }
    );

    if (rowData.length === column) {
      tableData.push(rowData);
      rowData = [];
    }
  });

  if (rowData.length > 0) {
    tableData.push(rowData);
  }

  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableData,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      cellPadding:
        panels.cellPadding || printDatasheetConfiguration.cellPaddingTable,
      fontSize: printDatasheetConfiguration.fontSizes.h4,
    },
  });

  layoutConfig.yPositionGlobal =
    printDatasheetConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawText = (
  text,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0,
  fontType
) => {
  printDatasheetConfiguration.pdfDoc.setFontSize(fontSize);
  if (fontType == "normalfont") {
    printDatasheetConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  } else {
    printDatasheetConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  }

  let yPos = ySize + topSpace;

  // Align the text horizontally
  let textWidth = printDatasheetConfiguration.pdfDoc.getTextWidth(text);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printDatasheetConfiguration.pdfDoc.internal.pageSize.width - textWidth) /
      2;
  } else if (align === "right") {
    xPos =
      printDatasheetConfiguration.pdfDoc.internal.pageSize.width -
      textWidth -
      xSize;
  }

  printDatasheetConfiguration.pdfDoc.text(text, xPos, yPos);
  layoutConfig.yPositionGlobal = yPos + fontSize + bottomSpace;

  if (fontType == "normalfont") {
    printDatasheetConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  } else {
    printDatasheetConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  }
};

export const drawTextBoldAndNormal = (
  boldText,
  normalText,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0
) => {
  printDatasheetConfiguration.pdfDoc.setFontSize(fontSize);

  let yPos = ySize + topSpace;
  let textWidth =
    printDatasheetConfiguration.pdfDoc.getTextWidth(boldText) +
    printDatasheetConfiguration.pdfDoc.getTextWidth(normalText);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printDatasheetConfiguration.pdfDoc.internal.pageSize.width - textWidth) /
      2;
  } else if (align === "right") {
    xPos =
      printDatasheetConfiguration.pdfDoc.internal.pageSize.width -
      textWidth -
      xSize;
  }

  // Draw the bold text first
  printDatasheetConfiguration.pdfDoc.setFont(
    customFontFamily?.boldFont,
    "bold"
  );
  printDatasheetConfiguration.pdfDoc.text(boldText, xPos, yPos);

  // Draw the normal text right after the bold part
  printDatasheetConfiguration.pdfDoc.setFont(
    customFontFamily?.normalFont,
    customFontFamily?.normal
  );
  printDatasheetConfiguration.pdfDoc.text(
    normalText,
    xPos + printDatasheetConfiguration.pdfDoc.getTextWidth(boldText) + 5,
    yPos
  );

  layoutConfig.yPositionGlobal = yPos + bottomSpace;
  printDatasheetConfiguration.pdfDoc.setFont(
    customFontFamily?.boldFont,
    "bold"
  );
};

export const environmenentTable = async (setPageSection) => {
  await setPageSection();

  let tableRows = [];

  const row = [
    {
      content: "Environmental Conditions:",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "left",
        valign:"middle"
      },
      rowSpan:2
    },
    {
      content: `Start Temp:`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.startTemp
        ) || "",
      styles: {
        halign: "left",
      },
    },
    
    ...(printdatasheetConfigData?.datasheetContextData?.datasheetDetails?.endTemp ? [
    {
      content: `End Temp:`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.endTemp
        ) || "",
      styles: {
        halign: "left",
      },
    },
    ] : []),
    {
      content: `Start Humidity:`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.startHumidity
        ) || "",
      styles: {
        halign: "left",
      },
    },
    ...(printdatasheetConfigData?.datasheetContextData?.datasheetDetails?.endHumidity ? [
    {
      content: `End Humidity:`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.endHumidity
        ) || "",
      styles: {
        halign: "left",
      },
    },
  ] : []),
  ];
  tableRows.push(row);

  const row2 = [
    ...(printdatasheetConfigData?.datasheetContextData?.datasheetDetails?.startPressure ? [
    {
      content: `Start "p":`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.startPressure
        ) || "",
      styles: {
        halign: "left",
      },
    },
  ] : []),
  ...(printdatasheetConfigData?.datasheetContextData?.datasheetDetails?.endPressure ? [
    {
      content: `End "p":`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.endPressure
        ) || "",
      styles: {
        halign: "left",
      },
    },
  ] : []),
  ...(printdatasheetConfigData?.datasheetContextData?.datasheetDetails?.startLiquidTemp ? [
    {
      content: `Start Liq.Temp:`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.startLiquidTemp
        ) || "",
      styles: {
        halign: "left",
      },
    },
  ] : []),
  ...(printdatasheetConfigData?.datasheetContextData?.datasheetDetails?.endLiquidTemp ? [
    {
      content: `End Liq.Temp:`,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "right",
      },
    },
    {
      content:
        parseValue(
          printdatasheetConfigData?.datasheetContextData?.datasheetDetails
            ?.endLiquidTemp
        ) || "",
      styles: {
        halign: "left",
      },
    }
  ] : []),
  ];
  // If second row has no data, adjust rowSpan of the first row
  if (row2.length === 0) {
    row[0].rowSpan = 1;
  } else {
  tableRows.push(row2);
  }

  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY:
      layoutConfig.yPositionGlobal -
      environmentTableConfig.spacebetweenHeadingandTable,
    margin: tableConfiguration?.margins,
    body: tableRows, // All following rows as table body
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printDatasheetConfiguration.textColor,
      lineColor: printDatasheetConfiguration.textColor,
      lineWidth: printDatasheetConfiguration.lineWidth,
      fontSize: printDatasheetConfiguration.fontSizes.h2,
      halign: "left",
      font: customFontFamily?.normalFont,
      cellPadding: environmentTableConfig.cellPadding,
    },
    tableLineColor: printDatasheetConfiguration.textColor,
    tableLineWidth: 0.1,
    bodyStyles: {
      lineWidth: environmentTableConfig.innerBorder,
    },
  });

  layoutConfig.yPositionGlobal =
    printDatasheetConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawUUCTable = async (setPageSection) => {
  await setPageSection();

  const panels = UUCDetails(
    printdatasheetConfigData?.datasheetContextData?.datasheetDetails
  );
  drawText(
    panels.heading,
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    panels.headingAlign,
    panels.headingFontSize,
    panels.headingTopSpace,
    panels.headingBottomSpace
  );

  let tableRows = [];
  panels.rows.forEach((row) => {
    const leftKey = row[0]?.key || "";
    const leftValue = row[0]?.value || "";
    const rightKey = row[1]?.key || "";
    const rightValue = row[1]?.value || "";
    tableRows.push([
      {
        content: String(leftKey), // Ensure content is a string
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
          valign: "middle",
        },
      },
      {
        content:
          panels.middleColumn && String(leftValue)
            ? `: ${String(leftValue)}`
            : String(leftValue), // Ensure content is a string
        styles: { halign: "left", valign: "middle" },
      },
      {
        content: String(rightKey), // Ensure content is a string
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
          valign: "middle",
        },
      },
      {
        content:
          panels.middleColumn && String(rightValue)
            ? `: ${String(rightValue)}`
            : String(rightValue), // Ensure content is a string
        styles: { halign: "left", valign: "middle" },
      },
    ]);
  });

  // Calculate the total available width of the table
  const totalPageWidth =
    printDatasheetConfiguration.pageWidth -
    tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 30, 20, 30];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  // Draw the table using autoTable
  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - panels.spacebetweenHeadingandTable,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printDatasheetConfiguration.textColor,
      lineColor: printDatasheetConfiguration.textColor,
      lineWidth: printDatasheetConfiguration.lineWidth,
      fontSize: panels.tableTextfontSize,
      font: customFontFamily?.normalFont,
      cellPadding: panels.cellPadding,
    },
    tableLineColor: printDatasheetConfiguration.textColor,
    tableLineWidth: 0.1,
    bodyStyles: {
      lineWidth: panels.innerBorder,
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });
  layoutConfig.yPositionGlobal =
    printDatasheetConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const detailsofstandards = async (setPageSection) => {
  await setPageSection();

  const panels = MasterEQPDetails(
    printdatasheetConfigData.datasheetContextData.selectedStandardMasters
  );
  if (panels.values.length === 0) {
    return;
  } 
  drawText(
    panels.heading,
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    panels.headingAlign,
    panels.headingFontSize,
    panels.headingTopSpace,
    panels.headingBottomSpace
  );

  let tableRows = [];
  const headerRow = panels.keys.map((key) => ({
    content: key,
    styles: {
      font: customFontFamily?.boldFont,
      fontStyle: customFontFamily?.bold,
      halign: "center",
    },
  }));
  tableRows.push(headerRow);
  panels.values.forEach((row) => {
    const dataRow = row.map((value) => ({
      content: value,
    }));
    tableRows.push(dataRow);
  });

  autoTable(printDatasheetConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - panels.spacebetweenHeadingandTable,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printDatasheetConfiguration.textColor,
      lineColor: printDatasheetConfiguration.textColor,
      lineWidth: printDatasheetConfiguration.lineWidth,
      fontSize:
        panels.tableTextfontSize || printDatasheetConfiguration.fontSizes.h2,
      halign: panels.tableValueAlign || "left",
      valign: "middle",
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printDatasheetConfiguration.cellPaddingTable,
    },
  });

  layoutConfig.yPositionGlobal =
    printDatasheetConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const remarksTable = async (remarksCondition, setPageSection) => {
  await setPageSection();
  if(!remarksCondition?.configuration?.remark) return;

  const pageWidth = printDatasheetConfiguration.pdfDoc.internal.pageSize.width;
  const pageHeight =
    printDatasheetConfiguration.pdfDoc.internal.pageSize.height;
  let remarksWidth = (pageWidth * 95) / 100;
  const margin = 10;

  // Calculate text height
  const calculateRemarksTextHeight = (remarks, width, fontSize) => {
    let totalHeight = 0;
    remarks.forEach((line) => {
      const wrappedText = printDatasheetConfiguration.pdfDoc.splitTextToSize(
        line,
        width
      );
      totalHeight += wrappedText.length * fontSize * 1.2;
    });
    return totalHeight;
  };

  // Prepare remarks text
  const remarksText = [];
  if (
    remarksCondition?.settingList["Compliance Status"]?.toLowerCase() === "true"
  ) {
    const complianceRemark = `The results ${
      remarksCondition?.complianceStatus == 1 ? "" : "don't"
    } comply with acceptance criteria.`;
    remarksText.push(complianceRemark);
  }

  if (remarksCondition?.calTypeSetting) {
    const calibrationOptions =
      remarksCondition?.settingList?.["CalibrationTypeOptions"]?.split(",") ||
      [];
    const reasonForCalibration =
      calibrationOptions[remarksCondition?.calibrationReason - 1] || "";
    remarksText.push(`Reason for Calibration: ${reasonForCalibration}`);
  }

  if (remarksCondition?.configuration?.remark) {
    remarksText.push(...remarksCondition.configuration.remark.split("\n"));
  }

  // Calculate remarks section height
  const remarksLabelHeight = printDatasheetConfiguration.fontSizes.h4 * 1.2;
  const remarksTextHeight = calculateRemarksTextHeight(
    remarksText,
    remarksWidth + margin,
    printDatasheetConfiguration.fontSizes.h4
  );
  const totalRemarksHeight = remarksLabelHeight + remarksTextHeight + 15;
  // console.log(layoutConfig.yPositionGlobal, totalRemarksHeight,layoutConfig.yPositionGlobal + totalRemarksHeight);
  // console.log(
  //   pageHeight -
  //     footerConfig.footerimgHeight -
  //     footerConfig.footertableHeight -
  //     footerConfig.footerandbodygap +
  //     25
  // );
  // Check if a new page is needed
  if (
    layoutConfig.yPositionGlobal + totalRemarksHeight >
    pageHeight -
      footerConfig.footerimgHeight -
      footerConfig.footertableHeight -
      footerConfig.footerandbodygap +
      25
  ) {
    await addNewPage();
    layoutConfig.yPositionGlobal =
      totalHeaderHeightWithCalibrationLableorNot.total + 5;
  }
  // console.log("before", layoutConfig.yPositionGlobal);

  // Draw "Remarks:" label
  if(remarksCondition?.configuration?.remark ||  remarksCondition?.settingList["Compliance Status"]?.toLowerCase() === "true"){
    drawText(
      "Remarks:",
      layoutConfig.xPositionGlobal,
      layoutConfig.yPositionGlobal,
      "left",
      printDatasheetConfiguration.fontSizes.h2,
      5,
      0
    );
  }

  layoutConfig.yPositionGlobal += remarksLabelHeight - 10;

  // Draw remarks text with wrapping
  const xPositionRemarks = layoutConfig.xPositionGlobal;
  remarksText.forEach((line) => {
    const wrappedText = printDatasheetConfiguration.pdfDoc.splitTextToSize(
      line?.trim()?.replace(/\t/g, ' '),
      remarksWidth + margin // Account for padding/margin
    );
    wrappedText.forEach((wrappedLine) => {
      drawText(
        wrappedLine,
        xPositionRemarks,
        layoutConfig.yPositionGlobal,
        "left",
        printDatasheetConfiguration.fontSizes.h4,
        0,
        0,
        "normalfont"
      );
      layoutConfig.yPositionGlobal += 2; // Increment position for the next line
    });
  });

  // console.log("afterremark", layoutConfig.yPositionGlobal);
  // console.log({ before, after });

  layoutConfig.yPositionGlobal += 5; // Final adjustment
};
