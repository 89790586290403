import moment from "moment";
import { toast } from "react-toastify";
import { BASE_URL, ID_BASE } from "../../../global";
import { generateNumber } from "../../../utils/utils";
import generateAudit from "../../audit/audit";
import { srfFields } from "../../../constants/srfConstants";
import { createSRFAnalytics } from "../../../utils/analytics/srfAnalytics";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

const historyData  = JSON.parse(sessionStorage.getItem("historicalTable")) || false;

// helper functions

const handleCERFForm = (CSRFFormPayload, insertId, isUpdate, csrfID) => {
  let payload = { ...CSRFFormPayload, srfId: insertId };

  if (isUpdate) {
    axiosWithToken
      .patch(BASE_URL + `csrfForm/${csrfID}`, payload)
      .then((res) => {
        console.log(res)
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  } else {
    axiosWithToken
      .post(BASE_URL + "csrfForm", payload)
      .then((res) => {
        // toast.success("CERF Form Save Successfully!");
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  }
};

export const handleCreateSubmit = async (
  srfDetails,
  readingRows,
  settings,
  setLoader,
  CSRFFormPayload,
  openErrorModal,
  latestSRFId,
  updateSrfDetails,
  countSRFInstruments
) => {
  setLoader(true);

  try {
    const srfRow = {
      clientId: srfDetails.clientId,
      receiptDate: srfDetails.receiptDate
        ? moment(srfDetails.receiptDate).format("YYYY-MM-DD")
        : null,
      dcNumber: srfDetails.dcNumber,
      dcDate: srfDetails.dcDate
        ? moment(srfDetails.dcDate).format("YYYY-MM-DD")
        : null,
      entryDate: srfDetails.entryDate
        ? moment(srfDetails.entryDate).format("YYYY-MM-DD")
        : null,
      committedDeliveryDate: srfDetails.committedDeliveryDate
        ? moment(srfDetails.committedDeliveryDate).format("YYYY-MM-DD")
        : null,
      serviceReqNumber: srfDetails.serviceReqNumber,
      companyHierarchy: srfDetails.companyHierarchy,
      description: srfDetails.description,
      address: srfDetails.address,
      poNumber: srfDetails.poNumber,
      contactPersonDetails: JSON.stringify(srfDetails.contactPersonDetails),
      poDate: srfDetails.poDate
        ? moment(srfDetails.poDate).format("YYYY-MM-DD")
        : null,
      billingId: srfDetails.billingId,
      billingAddress: srfDetails.billingAddress,
      createdby: localStorage.getItem("id"),
      customerAnnextureFilePaths:
        srfDetails.customerAnnextureFilePaths.join(",") || null,
      ducImages:
        srfDetails.ducImages?.join(",") || null,
      calType: srfDetails.calType || null,
    };

    let srfsPostResponse;
    let srfsServiceReqNumber = "";

    if (srfDetails?.id && srfDetails?.id > 0) {
      // Update existing SRF
      srfsPostResponse = await axiosWithToken.patch(
        BASE_URL + `srfs/${srfDetails.id}`,
        srfRow
      );
      srfsServiceReqNumber = srfDetails.serviceReqNumber;
    } else {
      // Create new SRF
      srfsPostResponse = await axiosWithToken.post(BASE_URL + "srfs", srfRow);
      const serviceReqNumberResponse = await axiosWithToken.post(BASE_URL + "dynamic", {"query":`select serviceReqNumber from srfs where id =${srfsPostResponse?.data?.insertId}`},{headers: {requestType: 'fetchData'}});

      srfsServiceReqNumber = serviceReqNumberResponse?.data?.[0]?.serviceReqNumber;
    }

    const srfId = srfsPostResponse?.data?.insertId;

    if (srfId && srfId > 0) {
      updateSrfDetails({ id: srfId });
    }


    const ducInwardData = readingRows.map((row, index) => {
      let certificateNumberByCalType;
      if (srfDetails?.calType === 1) {
        certificateNumberByCalType = settings["Certificate Number NABL"]?.value;
      } else if (srfDetails?.calType === 2) {
        certificateNumberByCalType = settings["Certificate Number IAS"]?.value;
      } else if (srfDetails?.calType === 3) {
        certificateNumberByCalType =
          settings["Certificate Number NONNABL"]?.value;
      }

      const formatDate = (date) =>
        date ? moment(date).format("YYYY-MM-DD") : null;

      const generateCertNumber = (row, base, id) =>
        row.id <= 0
          ? generateNumber(certificateNumberByCalType, `${base}/`, {
              entryDate: srfDetails?.entryDate,
              branch: srfDetails?.branch,
              discipline: srfDetails?.discipline,
              calibrationProcedureNo: srfDetails?.calibrationProcedureNo,
            }) + `${base}/${id + 1}`
          : undefined;

      const generateULRNumber = (row, id) =>
        row.id <= 0
          ? generateNumber(
              settings["ULR number"]?.value,
              Number(settings["ULR ID"]?.value) + countSRFInstruments + id
            )
          : undefined;


          let newLatestSRFId = srfsServiceReqNumber?.split("/");
          const baseId =  Number(newLatestSRFId?.[newLatestSRFId.length - 1]);
          

      return {
        ...row,
        id: row.id,
        receiptDate: formatDate(srfDetails.receiptDate),
        clientId: srfDetails.clientId,
        poNumber: srfDetails.poNumber,
        poDate: formatDate(srfDetails.poDate),
        address: srfDetails?.address,
        serviceReqNumber:srfsServiceReqNumber,
        JobNumber: `${baseId}/${index + 1}`,
        dcNumber: srfDetails?.dcNumber,
        DCDate: formatDate(srfDetails.dcDate),
        workOrderNumber: `${ID_BASE + Number(latestSRFId)}/WO`,
        srfInstrumentId: row.srfInstrumentId,
        instrumentId: row.instrumentId,
        serialNumber: row.serialNumber,
        requestedDucName: row.requestedDucName,
        ranges: row.ranges,
        lc: row.lc,
        make: row.make,
        model: row.model,
        materialType: row.materialType,
        department: row.department,
        operatingRange: row.operatingRange,
        DUCID: row.DUCID,
        ConOfDuc: row.ConOfDuc,
        calFrequency: row.calFrequency,
        accuracy: row.accuracy,
        calibrationType: srfDetails?.calType || 1,
        location: row.location,
        locationOfInstrument: row.locationOfInstrument,
        calPoint: row.calPoint,
        calMethod: row.calMethod,
        sensorType: row.sensorType,
        sensorCoefficient: row.sensorCoefficient,
        extraColumns: JSON.stringify(row.extraColumns),
        createdby: localStorage.getItem("id"),
        userId: localStorage.getItem("id"),
        approvedby: null,
        reviewedBy: null,
        signedBy: null,
        rejectedBy: null,
        witnessBy: null,
        approvedDate: null,
        reviewedDate: null,
        witnessDate: null,
        signedDate: null,
        certificateNumber: generateCertNumber(row, baseId, index),
        ULRNo: srfDetails?.calType == 1 ? generateULRNumber(row, index) : null,
      };
    });

    const schema = {
      srfInstruments: [
        "receiptDate",
        "clientId",
        "poNumber",
        "poDate",
        "address",
        "serviceReqNumber",
        "JobNumber",
        "dcNumber",
        "DCDate",
        "workOrderNumber",
        "srfInstrumentId",
        "instrumentId",
        "serialNumber",
        "requestedDucName",
        "ranges",
        "lc",
        "make",
        "model",
        "department",
        "operatingRange",
        "DUCID",
        "ConOfDuc",
        "calFrequency",
        "accuracy",
        "calibrationType",
        "location",
        "locationOfInstrument",
        "calPoint",
        "calMethod",
        "extraColumns",
        "createdby",
        "userId",
        "approvedby",
        "reviewedBy",
        "signedBy",
        "rejectedBy",
        "witnessBy",
        "approvedDate",
        "reviewedDate",
        "witnessDate",
        "signedDate",
        "certificateNumber",
        "ULRNo",
        "materialType",
        "sensorType",
        "sensorCoefficient",
      ],
      datasheet: [
        "receiptDate",
        "clientId",
        "poNumber",
        "poDate",
        "address",
        "serviceReqNumber",
        "JobNumber",
        "dcNumber",
        "DCDate",
        "workOrderNumber",
        "srfInstrumentId",
        "instrumentId",
        "serialNumber",
        "ranges",
        "lc",
        "make",
        "model",
        "department",
        "operatingRange",
        "DUCID",
        "ConOfDuc",
        "calFrequency",
        "accuracy",
        "calibrationType",
        "location",
        "locationOfInstrument",
        "calPoint",
        "calMethod",
        "extraColumns",
        "createdby",
      ],
      certificate: [
        "receiptDate",
        "clientId",
        "poNumber",
        "poDate",
        "dcNumber",
        "address",
        "serviceReqNumber",
        "DCDate",
        "instrumentId",
        "ranges",
        "srfInstrumentId",
        "lc",
        "serialNumber",
        "make",
        "model",
        "operatingRange",
        "department",
        "DUCID",
        "ConOfDuc",
        "accuracy",
        "calibrationType",
        "location",
        "locationOfInstrument",
        "calPoint",
        "calMethod",
        "extraColumns",
        "createdby",
        "approvedby",
        "reviewedBy",
        "signedBy",
        "rejectedBy",
        "witnessBy",
        "approvedDate",
        "reviewedDate",
        "witnessDate",
        "signedDate",
        "certificateNumber",
        "ULRNo",
      ],
    };

  
 

    const Payload = {
      srfId,
      ducInwardData,
      schema,
    };

    const response = await axiosWithToken.post(
      BASE_URL + "srfs/ducInward",
      Payload
    );

    let srfInstrumentIdsList = response?.data?.srfInstrumentsInsertIds;


    await createSRFAnalytics(srfId, srfInstrumentIdsList, 25);

    await handleCERFForm(CSRFFormPayload, srfId, false);

    toast.success("Inward created successfully!");

    setLoader(false);
  } catch (err) {
    handleError(
      err,
      openErrorModal,
      "handleCreateSubmit",
      "Unable to Create SRF. Please save again."
    );
    alert("Unable to Create SRF. Please save again.");
    setLoader(undefined);
  }
};

const handleError = (err, openErrorModal, functionName, errorHandler) => {
  console.log("Error occurred: ", err);
  if (err.message !== "request_aborted") {
    toast.error(
      <h6
        onClick={() =>
          openErrorModal(err.response.data.details, functionName, errorHandler)
        }
      >
        Error: Unable to Create/update SRF. Please save again.
        <u>View more</u>
      </h6>
    );
  }
};

export const handleUpdateSubmit = async (
  srfDetails,
  readingRows,
  srfInstrumentsToDelete,
  settings,
  setLoader,
  CSRFFormPayload,
  csrfID,
  openErrorModal,
  ducInwardOriginalRows,
  countSRFInstruments,
) => {
  let latestSRFId = srfDetails.serviceReqNumber?.split("/")?.pop();
  setLoader(true);
  let srfRow = {
    clientId: srfDetails.clientId,
    receiptDate: srfDetails.receiptDate
      ? moment(srfDetails.receiptDate).format("YYYY-MM-DD")
      : null,
    dcNumber: srfDetails.dcNumber,
    dcDate: srfDetails.dcDate
      ? moment(srfDetails.dcDate).format("YYYY-MM-DD")
      : null,
    entryDate: srfDetails.entryDate
      ? moment(srfDetails.entryDate).format("YYYY-MM-DD")
      : null,
    committedDeliveryDate: srfDetails.committedDeliveryDate
      ? moment(srfDetails.committedDeliveryDate).format("YYYY-MM-DD")
      : null,
    serviceReqNumber: srfDetails.serviceReqNumber,
    companyHierarchy: srfDetails.companyHierarchy,
    jobNumber: srfDetails.jobNumber,
    description: srfDetails.description,
    address: srfDetails.address,
    billingAddress: srfDetails.billingAddress,
    poNumber: srfDetails.poNumber,
    contactPersonDetails: JSON.stringify(srfDetails.contactPersonDetails),
    poDate: srfDetails.poDate
      ? moment(srfDetails.poDate).format("YYYY-MM-DD")
      : null,
    billingId: srfDetails.billingId,
    customerAnnextureFilePaths:
      srfDetails.customerAnnextureFilePaths.join(",") || null,
    ducImages:
        srfDetails.ducImages?.join(",") || null, 
    calType: srfDetails.calType || null,
  };
  
  let customIdCounter = 0; // Define a custom counter
  const ducInwardData = readingRows.map((row, index) => {
    let certificateNumberByCalType;
    if (srfDetails?.calType === 1) {
      certificateNumberByCalType = settings["Certificate Number NABL"]?.value;
    } else if (srfDetails?.calType === 2) {
      certificateNumberByCalType = settings["Certificate Number IAS"]?.value;
    } else if (srfDetails?.calType === 3) {
      certificateNumberByCalType =
        settings["Certificate Number NONNABL"]?.value;
    }

    const formatDate = (date) =>
      date ? moment(date).format("YYYY-MM-DD") : null;

    const generateCertNumber = (row, base, id) =>
      row.id <= 0
        ? generateNumber(certificateNumberByCalType, `${base}/`, {
            entryDate: srfDetails?.entryDate,
            branch: srfDetails?.branch,
            discipline: srfDetails?.discipline,
            calibrationProcedureNo: srfDetails?.calibrationProcedureNo,
          }) + `${base}/${id + 1}`
        : undefined;

    const generateULRNumber = (row, id) =>
      row.id <= 0
        ? generateNumber(
            settings["ULR number"]?.value,
            Number(settings["ULR ID"]?.value) + countSRFInstruments + id
          )
        : undefined;

    const baseId =  Number(latestSRFId);

    // Custom logic for creating ids that skip over certain indexes
    let customId = row.id == 0 ? customIdCounter++ : index;
    return {
      ...row,
      id: row.id,
      receiptDate: formatDate(srfDetails.receiptDate),
      srfsId: srfDetails?.id,
      clientId: srfDetails.clientId,
      poNumber: srfDetails.poNumber,
      poDate: formatDate(srfDetails.poDate),
      address: srfDetails?.address,
      serviceReqNumber: srfDetails.serviceReqNumber,
      JobNumber: `${baseId}/${index + 1}`,
      dcNumber: srfDetails?.dcNumber,
      DCDate: formatDate(srfDetails.dcDate),
      workOrderNumber: `${ID_BASE + Number(latestSRFId - 1)}/WO`,
      srfInstrumentId: row.srfInstrumentId,
      instrumentId: row.instrumentId,
      serialNumber: row.serialNumber,
      requestedDucName: row.requestedDucName,
      requestedname: row.requestedDucName,
      ranges: row.ranges,
      lc: row.lc,
      make: row.make,
      model: row.model,
      materialType: row.materialType,
      department: row.department,
      operatingRange: row.operatingRange,
      DUCID: row.DUCID,
      ConOfDuc: row.ConOfDuc,
      calFrequency: row.calFrequency,
      accuracy: row.accuracy,
      calibrationType: srfDetails?.calType || 1,
      location: row.location,
      locationOfInstrument: row.locationOfInstrument,
      calPoint: row.calPoint,
      calMethod: row.calMethod,
      sensorType: row.sensorType,
      sensorCoefficient: row.sensorCoefficient,
      extraColumns: JSON.stringify(row.extraColumns),
      createdby: localStorage.getItem("id"),
      userId: localStorage.getItem("id"),
      approvedby: null,
      reviewedBy: null,
      signedBy: null,
      rejectedBy: null,
      witnessBy: null,
      approvedDate: null,
      reviewedDate: null,
      witnessDate: null,
      signedDate: null,
      certificateNumber: generateCertNumber(row, baseId, index),
      ULRNo: srfDetails?.calType == 1 ? generateULRNumber(row, customId) : null,
    };
  });

  const schema = {
    srfInstruments: [
      "id",
      "receiptDate",
      "clientId",
      "poNumber",
      "poDate",
      "address",
      "serviceReqNumber",
      "JobNumber",
      "dcNumber",
      "DCDate",
      "workOrderNumber",
      "srfInstrumentId",
      "instrumentId",
      "serialNumber",
      "requestedDucName",
      "ranges",
      "lc",
      "make",
      "model",
      "department",
      "operatingRange",
      "DUCID",
      "ConOfDuc",
      "calFrequency",
      "accuracy",
      "calibrationType",
      "location",
      "locationOfInstrument",
      "calPoint",
      "calMethod",
      "extraColumns",
      "createdby",
      "userId",
      "approvedby",
      "reviewedBy",
      "signedBy",
      "rejectedBy",
      "witnessBy",
      "approvedDate",
      "reviewedDate",
      "witnessDate",
      "signedDate",
      "certificateNumber",
      "ULRNo",
      "materialType",
      "sensorType",
      "sensorCoefficient",
    ],
    datasheet: [
      "id",
      "receiptDate",
      "clientId",
      "poNumber",
      "poDate",
      "address",
      "serviceReqNumber",
      "requestedname",
      "JobNumber",
      "dcNumber",
      "DCDate",
      "workOrderNumber",
      "srfInstrumentId",
      "instrumentId",
      "serialNumber",
      "ranges",
      "lc",
      "make",
      "model",
      "department",
      "operatingRange",
      "DUCID",
      "ConOfDuc",
      "calFrequency",
      "accuracy",
      "calibrationType",
      "location",
      "locationOfInstrument",
      "calPoint",
      "calMethod",
      "extraColumns",
      "createdby",
    ],
    certificate: [
      "id",
      "receiptDate",
      "clientId",
      "poNumber",
      "poDate",
      "dcNumber",
      "address",
      "serviceReqNumber",
      "DCDate",
      "instrumentId",
      "ranges",
      "srfInstrumentId",
      "lc",
      "serialNumber",
      "make",
      "model",
      "operatingRange",
      "department",
      "DUCID",
      "ConOfDuc",
      "accuracy",
      "calibrationType",
      "location",
      "locationOfInstrument",
      "calPoint",
      "calMethod",
      "extraColumns",
      "createdby",
      "approvedby",
      "reviewedBy",
      "signedBy",
      "rejectedBy",
      "witnessBy",
      "approvedDate",
      "reviewedDate",
      "witnessDate",
      "signedDate",
      "certificateNumber",
      "ULRNo",
    ],
  };

  const srfsResponse = await axiosWithToken.patch(
    BASE_URL + `srfs/${srfDetails?.id}`,
    srfRow
  );

  const ducInwardDataToInsert = ducInwardData?.filter(
    (row) => !row.id || row.id < 1
  );

  const ducInwardDataToUpdate = ducInwardData?.filter(
    (row) => row.id && row.id >= 1
  );

  let srfId = srfDetails?.id > 0 ? srfDetails?.id : null;


  const keysToExclude = [
    "receiptDate",
    "clientId",
    "poNumber",
    "poDate",
    "address",
    "JobNumber",
    "dcNumber",
    "DCDate",
    "workOrderNumber",
    "createdby",
    "userId",
    "approvedby",
    "reviewedBy",
    "signedBy",
    "rejectedBy",
    "witnessBy",
    "approvedDate",
    "reviewedDate",
    "witnessDate",
    "signedDate",
    "id",
    "serviceReqNumber",
    "ULRNo",
  ];

  const keysToCompare = schema?.srfInstruments.filter(
    (key) => !keysToExclude.includes(key)
  );

  const { changedRowIds, changedKeys } = findChangedRows(
    ducInwardDataToUpdate,
    ducInwardOriginalRows,
    keysToCompare
  );
  
  const filteredDucInwardDataToUpdate = ducInwardDataToUpdate?.filter(item => changedRowIds.includes(item.id));


  let insertPayload = { srfId, ducInwardData: ducInwardDataToInsert, schema , historyData };
  let updatePayload = { srfId, ducInwardData: filteredDucInwardDataToUpdate, schema, historyData  };


  if (ducInwardDataToInsert.length > 0) {
    axiosWithToken
      .post(BASE_URL + "srfs/ducInward", insertPayload)
      .then(async (response) => {
        // setLoader(false)
        let srfInstrumentIdsList = response?.data?.srfInstrumentsInsertIds;
        await createSRFAnalytics(srfDetails?.id, srfInstrumentIdsList, 25);
      })
      .catch((err) => {
        handleError(
          err,
          openErrorModal,
          "handleUpdateSubmit",
          "Unable to Create SRF. Please save again."
        );
        setLoader(undefined);
        alert("Unable to Create SRF. Please save again.");
      });
  }
  if (filteredDucInwardDataToUpdate.length > 0) {
    
    axiosWithToken
      .patch(BASE_URL + "srfs/ducInward", updatePayload)
      .then(async(res) => {
        await deleteOldDatasheetStaticReadings(ducInwardDataToUpdate, ducInwardOriginalRows);
        // toast.success("SRF Updated successfully!");
        // setLoader(false)
      })
      .catch((err) => {
        handleError(
          err,
          openErrorModal,
          "handleUpdateSubmit",
          "Unable to Update SRF. Please save again."
        );
        setLoader(undefined);
        alert("Unable to Update SRF. Please save again.");
      });

      await certificateReapprove(ducInwardDataToUpdate, ducInwardOriginalRows, schema?.srfInstruments)

  }

  if (srfInstrumentsToDelete?.length > 0)
    await handleDelete(srfInstrumentsToDelete);

  toast.success("Inward Updated successfully!");
  setLoader(false);

  await handleCERFForm(CSRFFormPayload, srfDetails?.id, true, csrfID);
};

const deleteOldDatasheetStaticReadings = async (ducInwardNewRows, ducInwardOriginalRows) => {
  try {
    if (!Array.isArray(ducInwardNewRows) || !Array.isArray(ducInwardOriginalRows)) {
      throw new Error("Invalid input: Both parameters must be arrays.");
    }

    const changedIds = ducInwardNewRows.reduce((acc, newRow, index) => {
      const originalRow = ducInwardOriginalRows[index];
      
      // Ensure row data exists and compare instrumentId values
      if (originalRow && newRow && newRow.instrumentId !== originalRow.instrumentId) {
        acc.push(String(newRow.id));
      }
      return acc;
    }, []);

    if (changedIds.length > 0) {
      const deleteQuery ={"query": `
        DELETE FROM datasheetStaticReadings WHERE datasheetId IN (${changedIds?.map(id => `'${id}'`)?.join(", ")});
      `};

      // Execute the SQL query using axios
      await axiosWithToken.post(BASE_URL + "dynamic", deleteQuery);
    
    } 
  } catch (error) {
    console.error("Error:", error.message);
  }
};



export const certificateReapprove = async(
  ducInwardNewRows,
  ducInwardOriginalRows,
  srfInstrumentsKeys
) => {
  const keysToExclude = [
    "receiptDate",
    "clientId",
    "poNumber",
    "poDate",
    "address",
    "JobNumber",
    "dcNumber",
    "DCDate",
    "workOrderNumber",
    "createdby",
    "userId",
    "approvedby",
    "reviewedBy",
    "signedBy",
    "rejectedBy",
    "witnessBy",
    "approvedDate",
    "reviewedDate",
    "witnessDate",
    "signedDate",
    "id",
    "serviceReqNumber",
    "ULRNo"
  ];

  const keysToCompare = srfInstrumentsKeys.filter(
    (key) => !keysToExclude.includes(key)
  );

  const { changedRowIds, changedKeys } = findChangedRows(
    ducInwardNewRows,
    ducInwardOriginalRows,
    keysToCompare
  );

  let sqlQuery={
    "query":`UPDATE certificates SET approvedby = NULL, reviewedBy = NULL, signedBy = NULL, rejectedBy = NULL, witnessBy = NULL, approvedDate = NULL, reviewedDate = NULL, witnessDate = NULL, signedDate = NULL WHERE id IN (${changedRowIds?.join(",")})`
  }


 if(changedRowIds.length > 0){
 let CertificateReapproveReq= await axiosWithToken.post(BASE_URL + "dynamic",sqlQuery, {headers: {requestType: 'submitData'}})
 }

};

function findChangedRows(
  ducInwardNewRows,
  ducInwardOriginalRows,
  keysToCompare
) {
  const changedRowIds = new Set();
  const changedKeys = new Set();

  ducInwardNewRows.forEach((newRow) => {
    const id = newRow.id;
    const ducInwardRow = ducInwardOriginalRows.find((row) => row.id === id);

    if (ducInwardRow) {
      keysToCompare.forEach((key) => {
        if (newRow[key] !== ducInwardRow[key]) {
          changedRowIds.add(id);
          changedKeys.add(key);
        }
      });
    }
  });

  return { changedRowIds: Array.from(changedRowIds), changedKeys: Array.from(changedKeys) };
}


export const handleDelete = async (srfInstrumentsToDelete) => {
  try {
    const deleteIds = srfInstrumentsToDelete?.join(",");
    const userId=localStorage.getItem("id")

    const deleteQueries = [
    `UPDATE srfInstruments SET status = -1, createdBy = ${userId} WHERE id IN (${deleteIds})`,
  `UPDATE datasheets SET status = -1, createdBy = ${userId} WHERE id IN (${deleteIds})`,
  `UPDATE certificates SET status = -1, createdBy = ${userId} WHERE id IN (${deleteIds})`,
  `UPDATE srfAnalytics SET status = -1 WHERE srfInstrumentId IN (${deleteIds})`,
    ];

    // Execute all delete queries concurrently using Promise.all
    await Promise.all(
      deleteQueries.map((query) => {
        return axiosWithToken.post(BASE_URL + `dynamic`, { query }, {headers: {requestType: 'submitData'}});
      })
    );

    console.log("Deletion successful");
  } catch (error) {
    console.error("Error deleting records:", error);
  }
};
